import { gql } from '@apollo/client';

export const SIDE_MENU = gql`
  query SideMenu($menuSlug: ID! = "mobile-side") {
    menu(id: $menuSlug, idType: SLUG) {
      menuItems {
        nodes {
          databaseId
          parentDatabaseId
          name: label
          slug: connectedNode {
            node {
              type: __typename
              ... on ProductCategory {
                slug
              }
              ... on ProductBrand {
                slug
              }
              ... on ProductTag {
                slug
              }
              ... on ProductPerfumeNote {
                slug
              }
              ... on ProductCollection {
                slug
              }
              ... on Page {
                slug
              }
              ... on Post {
                slug
              }
              ... on Category {
                slug
              }
              ... on Tag {
                slug
              }
            }
          }
        }
      }
    }
  }
`;
