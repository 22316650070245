import 'Styles/index.scss';
import 'nprogress/nprogress.css';

import { StrictMode, useEffect, useState } from 'react';

import { ApolloProvider } from '@apollo/client';
import Modal from 'Components/Modal';
import { AppProvider } from 'Context/AppContext';
import { MenuProvider } from 'Context/MenuContext';
import client from 'Lib/ApolloClient';
import Image from 'next/image';
import Router from 'next/router';
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }) {
  // const { shouldReload } = useBuildId();

  // useEffect(() => {
  //   if (shouldReload) {
  //     window.location.reload();
  //   }
  // });


  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (sessionStorage.getItem('shouldShowPopup') !== 'false') {
      setTimeout(() => {
        setOpen(true)
      }, 2000)
    }

  }, [])

  const handleClose = () => {
    setOpen(false)
    if (typeof window !== undefined) {
      sessionStorage.setItem('shouldShowPopup', false)
    }
  }

  return (
    <StrictMode>
      <AppProvider>
        <ApolloProvider client={client}>
          <MenuProvider>
            <Component {...pageProps} />
            <div>
              {process.env.NEXT_PUBLIC_SHOW_POPUP === 'true' && typeof window !== undefined ?
                <Modal open={open} onClose={handleClose}>
                  <Image src={process.env.NEXT_PUBLIC_POPUP_IMG} width={400} height={400} alt="sale banner" />
                </Modal>
                : null}
            </div>

          </MenuProvider>
        </ApolloProvider>
      </AppProvider>
    </StrictMode>
  );
}

export default App;
