export const PATH = {
  PRODUCT: '/product',
  CATEGORY: '/category',
  BLOG: '/blog',
  CART: '/checkout/cart',
  ADD_ADDRESS: '/checkout/add-address',
  ADDRESS_LIST: '/checkout/customer-address',
  MOBILESEARCH: '/mobilesearch',
  SEARCH: '/search',
  SUCCESS: '/success',
  CREATE_VNP_URL: '/api/payment/vnpay/create-payment-url'
};

export const URI_TYPE_PATH = {
  ProductCategory: '/category',
  ProductTag: '/tag',
  ProductBrand: '/brands',
  ProductPerfumeNote: '/perfumenotes',
  ProductCollection: '/collection',
  Page: '/content'
};

export const ALLOW_LIST_TYPE = [
  'category',
  'tag',
  'brands',
  'perfumenotes',
  'collection'
]