import { URI_TYPE_PATH } from 'Lib/constants/path';

export const getMenuItems = (items) => {
  let result = [];
  items &&
    items.forEach((elem) => {
      if (getMenuTypeUri(elem.slug.node.type)) {
        result.push({
          id: elem.databaseId,
          parent: elem.parentDatabaseId,
          name: elem.name,
          path: `${getMenuTypeUri(elem.slug.node.type)}/${elem.slug.node.slug}`,
          slug: elem.slug.node.slug,
          uri: elem.slug.node?.uri,
        });
      }
    });

  return result;
};

const getMenuTypeUri = (menuType) => {
  if (Object.keys(URI_TYPE_PATH).indexOf(menuType) > -1) {
    return URI_TYPE_PATH[menuType];
  }

  return null;
};
