import React, { useEffect, useState } from 'react';

import { SIDE_MENU } from 'Queries/SIDE_MENU';
import { getMenuItems } from 'utils/menu';
import { useQuery } from '@apollo/client';

export const MenuContext = React.createContext([{}, () => {}]);

export const MenuProvider = (props) => {
  const [sideMenuItems, setSideMenuItem] = useState(null);

  const { data } = useQuery(SIDE_MENU, {
    onCompleted: () => {
      const menuItems = getMenuItems(data?.menu.menuItems.nodes);
      setSideMenuItem(menuItems);
    }
  });

  useEffect(() => {
    const menuItems = getMenuItems(data?.menu.menuItems.nodes);
    setSideMenuItem(menuItems);
  }, [data]);

  return (
    <MenuContext.Provider value={{ sideMenuItems }}>
      {props.children}
    </MenuContext.Provider>
  );
};
